/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.subscription-cancel {
  max-width: 685px;
  text-align: center;
}
.subscription-cancel__first-step {
  margin-bottom: 36px;
}
.subscription-cancel__first-step__content {
  padding: 12px 24px;
}
.subscription-cancel__first-step__content__detail {
  text-align: start;
  font-size: 0.875rem;
  color: #eee;
}
.subscription-cancel__first-step__content__detail:last-child {
  margin-bottom: 12px;
}
.subscription-cancel__first-step__avatar {
  width: 36px;
  height: 36px;
  border: 1px solid rgba(255, 255, 255, 0.48);
  box-sizing: border-box;
  border-radius: 100%;
  margin-right: 12px;
}
.subscription-cancel__first-step__title {
  color: #e86464;
  font-weight: 600;
  font-size: 0.875rem;
}
.subscription-cancel__first-step__buttons {
  display: grid;
  min-height: 60px;
}
@media (min-width: 480px) {
  .subscription-cancel__first-step__buttons {
    display: flex;
  }
}
.subscription-cancel__first-step__buttons > button {
  flex: 1;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: none;
}
.subscription-cancel__first-step__buttons > button:first-child {
  margin-right: 0px;
  margin-bottom: 12px;
}
@media (min-width: 480px) {
  .subscription-cancel__first-step__buttons > button:first-child {
    margin-right: 12px;
    margin-bottom: 0px;
  }
}
.subscription-cancel__second-step__content {
  padding: 12px 24px;
  text-align: start;
  min-height: 290px;
  display: flex;
  justify-content: center;
  position: relative;
}
.subscription-cancel__second-step__content--child {
  padding-top: 72px;
}
.subscription-cancel__second-step__close {
  position: absolute;
  top: 12px;
  right: 24px;
  background: rgba(100, 100, 115, 0.2);
  border-radius: 6px;
  border: none;
  color: #fefefe;
  padding: 6px;
  cursor: pointer;
}
.subscription-cancel__second-step__title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 6px;
}
.subscription-cancel__second-step__description {
  color: #fff;
  font-size: 0.875rem;
}
.subscription-cancel__second-step__buttons {
  position: absolute;
  bottom: 36px;
  right: 24px;
}
.subscription-cancel__second-step__buttons > button {
  background-color: #fefefe;
}