/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.crafting-view__confirmation, .crafting-view__option, .crafting-view__card, .crafting-view__addition {
  background: #22222a;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
}

.crafting-view {
  padding: 12px;
  display: grid;
  grid-template-columns: minmax(180px, 225px) minmax(230px, 290px) minmax(180px, 225px);
  grid-template-areas: "destroying card crafting";
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px) {
  .crafting-view {
    grid-gap: 24px;
    grid-template-columns: repeat(2, minmax(140px, 250px));
    grid-template-areas: "card card " "destroying crafting";
  }
}
@media (max-width: 400px) {
  .crafting-view {
    grid-gap: 12px;
  }
}
.crafting-view__card-content {
  grid-area: card;
  max-width: 290px;
  justify-self: center;
  width: 100%;
}
.crafting-view__addition {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}
@media (min-width: 41.87em) {
  .crafting-view__addition {
    padding: 12px 24px;
  }
}
.crafting-view__addition--top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.crafting-view__addition--bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.crafting-view__addition__number {
  font-size: 1.125rem;
  font-weight: 600;
}
.crafting-view__addition__label {
  margin-right: 6px;
}
.crafting-view__card {
  text-align: center;
  padding: 12px;
  position: relative;
  z-index: 1;
}
.crafting-view__card img {
  max-height: 260px;
}
@media (min-width: 41.87em) {
  .crafting-view__card {
    padding: 24px 36px;
  }
  .crafting-view__card img {
    max-height: 350px;
  }
}
.crafting-view__option {
  position: relative;
  padding: 36px 12px;
  overflow: hidden;
  grid-area: crafting;
}
@media (min-width: 600px) {
  .crafting-view__option {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.crafting-view__option--destroying {
  grid-area: destroying;
}
@media (min-width: 600px) {
  .crafting-view__option--destroying {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}
@media (min-width: 41.87em) {
  .crafting-view__option {
    padding: 36px 24px;
  }
}
.crafting-view__option__input {
  margin-bottom: 12px;
}
.crafting-view__option__label {
  color: #fefefe;
  font-weight: bold;
  text-align: center;
  margin-bottom: 12px;
}
@media (min-width: 41.87em) {
  .crafting-view__option__label {
    font-size: 1.125rem;
  }
}
.crafting-view__option__resources {
  padding: 12px;
  background: rgba(100, 100, 115, 0.2);
  color: #fefefe;
  font-weight: 600;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 12px;
}
@media (min-width: 41.87em) {
  .crafting-view__option__resources {
    font-size: 1.125rem;
  }
}
.crafting-view__option__resources img {
  margin-left: 3px;
}
.crafting-view__blocked {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 24px;
  color: #fefefe;
  background: rgba(14, 14, 17, 0.9);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.crafting-view__action {
  text-transform: none;
  font-size: 0.875rem;
}
.crafting-view__confirmation {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  transform: translateY(101%);
  transition: all 0.3s ease-out;
  padding: 36px 12px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
}
@media (min-width: 41.87em) {
  .crafting-view__confirmation {
    padding: 36px 24px;
  }
}
.crafting-view__confirmation--show {
  transform: translateY(0);
}
.crafting-view__confirmation button {
  text-transform: none;
  font-size: 0.875rem;
}
.crafting-view__confirmation__label {
  margin-bottom: 4px;
}
.crafting-view__confirmation__value {
  font-weight: 600;
  color: #fefefe;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
@media (min-width: 41.87em) {
  .crafting-view__confirmation__value {
    font-size: 1.125rem;
  }
}
.crafting-view__confirmation__value img {
  margin-left: 6px;
}