/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.public-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 75px;
  flex: 1;
  z-index: 300;
  width: 100%;
  opacity: 1;
  background: rgba(14, 14, 17, 0.5);
  backdrop-filter: blur(10px);
  top: 65px;
  right: 0;
}
@supports not (backdrop-filter: blur(10px)) {
  .public-header {
    background: rgba(14, 14, 17, 0.9);
  }
}
@media (min-width: 64em) {
  .public-header {
    width: calc(100% - 230px);
  }
}
@media (min-width: 1300px) {
  .public-header {
    width: calc(100% - 300px);
  }
}
@media (min-width: 769px) {
  .public-header {
    position: fixed;
  }
}
.public-header__actions button {
  font-size: 1.125rem;
  margin-left: 12px;
}
.public-header__page {
  display: flex;
  align-items: center;
  padding-left: 12px;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .public-header__page {
    width: 100%;
  }
}
.public-header__avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
@media (min-width: 41.87em) {
  .public-header__avatar {
    height: 44px;
    width: 44px;
    margin: 5px 0 3px;
  }
}
.public-header__slug {
  display: flex;
  flex-direction: column;
  transform: translateY(1px);
  margin-left: 6px;
}
.public-header__title {
  font-size: 0.875rem;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 3px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .public-header__title {
    max-width: 230px;
  }
}
.public-header__badge {
  width: 24px;
  filter: drop-shadow(0px 0px 5px black);
  transform: translate(-15px, 10px);
}