/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
/* Own constants */
.nav-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 24px);
  max-width: 1400px;
  margin: 0 auto 36px;
}
.nav-tabs__container {
  max-width: 1424px;
  width: 100%;
  border-bottom: 1px solid #42424d;
}
.nav-tabs__items {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  max-width: 100%;
  overflow: auto;
}
@media (max-width: 768px) {
  .nav-tabs__items {
    margin: 0 auto;
  }
}
.nav-tabs__item {
  display: flex;
  align-items: center;
  color: #ededed;
  font-weight: 600;
  position: relative;
  padding: 18px 0;
  cursor: pointer;
}
@media (min-width: 41.87em) {
  .nav-tabs__item {
    font-size: 1rem;
  }
}
.nav-tabs__item + .nav-tabs__item {
  margin-left: 24px;
}
@media (min-width: 33.75em) {
  .nav-tabs__item + .nav-tabs__item {
    margin-left: 36px;
  }
}
.nav-tabs__item__icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
@media (max-width: 539px) {
  .nav-tabs__item__icon {
    display: none;
  }
}
.nav-tabs__item__new {
  margin-left: 3px;
}
.nav-tabs__item--selected {
  height: 4px;
  background: linear-gradient(135deg, #7419ff 0%, #3946ff 100%);
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.nav-tabs__count {
  background: #cc0022;
  color: #fefefe;
  padding: 0 3.6px;
  margin-left: 6px;
  border-radius: 3px;
}