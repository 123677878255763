/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.buy-packs-button svg {
  margin: -8px 0 9px;
  color: #eee;
}
.buy-packs-button::after, .buy-packs-button::before {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.buy-packs-button::after {
  left: calc(50% - 22px);
  top: -12px;
  width: 44px;
  height: 44px;
  z-index: -2;
  background-color: #fa2566;
  background-image: linear-gradient(to bottom, #ffcd1e, #fa2566);
}
.buy-packs-button::before {
  left: calc(50% - 20px);
  top: -10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: -1;
  background-color: #41414d;
}
.buy-packs-button:hover::after {
  transform: scale(2);
  animation: rotateRing 1s linear infinite;
}

@keyframes rotateRing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}