/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.subscription-claim {
  display: flex;
  align-items: center;
  max-width: 1400px;
  width: calc(100% - 24px);
  margin: 12px auto 36px;
}
.subscription-claim__panel {
  display: flex;
}

.subscription-gifter {
  display: flex;
  color: #fff;
  padding: 12px;
  border-radius: 12px;
  align-items: center;
  min-width: 342px;
  margin-right: 24px;
  background: #202574;
  border: solid 1px #3845ff;
  overflow: hidden;
  position: relative;
}
.subscription-gifter > * {
  z-index: 1;
}
.subscription-gifter::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(58, 71, 248, 0) 0, rgba(58, 71, 248, 0.2) 20%, rgba(58, 71, 248, 0.5) 60%, rgba(58, 71, 248, 0));
  animation: shimmer 2s infinite;
  content: "";
  z-index: 0;
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.subscription-gifter:last-child {
  margin-right: 0;
}
@media (min-width: 769px) {
  .subscription-gifter {
    padding: 12px 24px;
    min-width: 368px;
  }
}
.subscription-gifter__avatar {
  width: 62px;
  height: 62px;
  border-radius: 50%;
}
.subscription-gifter__text {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  flex-grow: 1;
  font-size: 0.875rem;
}
.subscription-gifter__text--name {
  font-weight: 600;
}
.subscription-gifter__text--description {
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 3px;
}
.subscription-gifter__text--description__months {
  font-weight: 700;
}
.subscription-gifter__text--description__months--multiple {
  color: #ffd95c;
}
.subscription-gifter__counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 6px;
  color: #fff;
  min-width: 66px;
}
.subscription-gifter__counter--number {
  font-size: 2rem;
  font-weight: 600;
}
.subscription-gifter__counter--label {
  font-size: 0.75rem;
  font-weight: 600;
}

.announcement {
  display: flex;
  color: #fff;
  border-radius: 12px;
  align-items: center;
  min-width: 342px;
  max-width: 650px;
  background: #202574;
  border: solid 1px #3845ff;
  position: relative;
  margin: 0 auto 36px;
  padding: 12px;
  gap: 12px;
}
.announcement__avatar {
  width: 62px;
  height: 62px;
}
.announcement__text {
  margin-bottom: 0;
}
.announcement__info {
  display: flex;
  align-items: center;
  gap: 12px;
}
@media (max-width: 669px) {
  .announcement__info {
    flex-wrap: wrap;
  }
}