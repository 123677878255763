/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.subs {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto 12px;
  width: calc(100% - 24px);
}
.subs__title {
  font-size: 1.5rem;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 769px) {
  .subs__title {
    justify-content: flex-start;
  }
}
.subs__title__star {
  margin-left: 12px;
  background-image: url("./assets/star.svg");
  background-size: cover;
  height: 42px;
  width: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 600;
  padding-top: 2px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.6);
}
.subs__need {
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 6px;
  width: 100%;
  text-align: center;
}
@media (min-width: 769px) {
  .subs__need {
    text-align: left;
  }
}
.subs__alerts {
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 48px;
  row-gap: 48px;
  width: 100%;
}
@media (min-width: 41.87em) {
  .subs__alerts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 64em) {
  .subs__alerts {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1600px) {
  .subs__alerts {
    grid-template-columns: repeat(4, 1fr);
  }
}
.subs__alerts__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.5), 0px 15px 10px rgba(0, 0, 0, 0.36);
  background: #1a1a1e;
  overflow: hidden;
}
.subs__alerts__item__image {
  position: relative;
  height: 218px;
  width: 100%;
  overflow: hidden;
}
.subs__alerts__item__image video {
  height: 218px;
  width: 100%;
  object-fit: cover;
}
.subs__alerts__item__image--play {
  cursor: pointer;
  position: absolute;
  bottom: 11px;
  right: 12px;
}
.subs__alerts__item__image--play > svg {
  width: 36px;
  height: 36px;
}
.subs__alerts__item__content {
  width: 100%;
  padding: 18px 24px 24px;
}
.subs__alerts__item__title {
  font-size: 1.125rem;
  color: #fff;
  font-weight: 700;
  width: 100%;
  margin-bottom: 12px;
}
.subs__alerts__item__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subs__alerts__item__footer button {
  text-transform: none;
  width: 100%;
  height: 42px;
}
.subs__alerts__item__footer__cooldown {
  margin-right: 6px;
}
.subs__emotes {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.5), 0px 15px 10px rgba(0, 0, 0, 0.36);
  background: #1a1a1e;
  width: 100%;
  height: 344px;
  min-height: 344px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 41.87em) {
  .subs__emotes {
    grid-column: 1/3;
  }
}
@media (max-width: 669px) {
  .subs__emotes {
    height: 370px;
    min-height: 370px;
  }
}
.subs__emotes--loading {
  padding: 24px;
}
.subs__emotes__header {
  padding: 24px 24px 0;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 669px) {
  .subs__emotes__header {
    flex-wrap: wrap;
  }
}
.subs__emotes__header-title {
  font-size: 1.125rem;
  padding-right: 12px;
}
@media (max-width: 669px) {
  .subs__emotes__header-title {
    padding-bottom: 12px;
  }
}
.subs__emotes__search {
  max-width: 140px;
  align-self: flex-end;
}
.subs__emotes__search input {
  background: transparent;
}
.subs__emotes__content {
  padding: 0 24px;
  overflow-y: auto;
  flex-grow: 1;
  padding-bottom: 18px;
}
.subs__emotes__footer {
  border-top: 1px solid rgba(100, 100, 115, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 24px 24px;
}
@media (min-width: 480px) {
  .subs__emotes__footer {
    flex-direction: row;
  }
}
.subs__emotes__footer--text {
  position: relative;
  flex-grow: 1;
  background: rgba(100, 100, 115, 0.2);
  border: 1px solid #646473;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 6px 48px 6px 12px;
  min-height: 43px;
  height: 43px;
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 100%;
  margin-bottom: 18px;
}
@media (min-width: 480px) {
  .subs__emotes__footer--text {
    width: auto;
    margin-bottom: 0;
  }
}
.subs__emotes__footer--text__content {
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #a6a6b5;
}
.subs__emotes__footer--text__content > img {
  max-width: 28px;
  max-height: 28px;
  margin-right: 3px;
}
.subs__emotes__footer--text__clear {
  position: absolute;
  top: 9px;
  right: 12px;
  border-width: 0;
  padding: 0;
}
.subs__emotes__footer--text__clear > svg {
  width: 24px;
  height: 24px;
}
.subs__emotes__footer__send {
  height: 42px;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}
@media (min-width: 480px) {
  .subs__emotes__footer__send {
    margin-left: 12px;
    width: auto;
  }
  .subs__emotes__footer__send--full {
    margin-left: 0;
    width: 100%;
  }
}
.subs__emotes__footer__send--coming-soon, .subs__emotes__footer__send--coming-soon:hover {
  border: none;
  color: #60ffcc;
  background: #22222a;
}
.subs__emotes__collection {
  margin-bottom: 18px;
}
.subs__emotes__collection__name {
  font-size: 0.875rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 6px;
}
.subs__emotes__collection__tiles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.subs__emotes__collection__tiles__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.subs__emotes__collection__tiles__tile:hover {
  border-radius: 3px;
  background-color: rgba(14, 14, 17, 0.85);
}
.subs__emotes__collection__tiles__tile > img {
  max-width: 28px;
  max-height: 28px;
}

:global .rc-tooltip {
  min-width: auto;
}
:global .rc-tooltip-inner {
  background-color: #42424d;
  border: rgba(100, 100, 115, 0.5);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.48), 0px 6px 6px rgba(0, 0, 0, 0.36);
}