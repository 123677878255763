/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.intro-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  flex-basis: auto;
}
@media (min-width: 769px) {
  .intro-banner {
    padding: 48px 0;
  }
}
@media (max-width: 768px) {
  .intro-banner {
    flex-direction: column;
  }
}
.intro-banner__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 12px;
  padding-left: 12px;
  position: relative;
  width: 100%;
  max-width: 1424px;
}
@media (min-width: 769px) {
  .intro-banner__container {
    padding-top: 12px;
    flex-direction: row;
    justify-content: space-between;
  }
}
.intro-banner__container__ctas {
  padding: 0 9px;
  margin-top: 12px;
  align-items: flex-end;
  display: flex;
}
@media (min-width: 33.75em) {
  .intro-banner__container__ctas {
    padding: 0 36px 18px;
  }
}
@media (min-width: 769px) {
  .intro-banner__container__ctas {
    margin-top: 0;
  }
}
.intro-banner__container__ctas__subscribed-until {
  width: 180px;
  background: rgba(56, 69, 255, 0.36);
  border: 2px solid #3845ff;
  box-sizing: content-box;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  color: #eee;
  font-size: 0.875rem;
  padding: 3px;
  margin-bottom: 24px;
  margin-top: 12px;
  height: 42px;
  display: grid;
}
.intro-banner__container__ctas__subscribed-until--first-child {
  margin-right: 3px;
}
@media (min-width: 769px) {
  .intro-banner__container__ctas__subscribed-until--first-child {
    margin-right: 0px;
  }
}
@media (min-width: 769px) {
  .intro-banner__container__ctas__subscribed-until {
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    height: 56px;
  }
}
.intro-banner__container__ctas__subscribe {
  width: 180px;
  text-transform: none;
  padding: 3px;
  margin-bottom: 24px;
  margin-top: 12px;
  height: 42px;
  font-size: 1.125rem;
  font-weight: 600;
}
@media (min-width: 769px) {
  .intro-banner__container__ctas__subscribe {
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    height: 60px;
  }
}
.intro-banner__container__ctas__subscribed {
  position: relative;
  background: #2a3079;
  border: 2px solid #3845ff;
  box-sizing: content-box;
  border-radius: 6px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eee;
  font-weight: 600;
  font-size: 1.125rem;
  padding: 3px;
  margin-bottom: 24px;
  margin-top: 12px;
  height: 42px;
}
@media (min-width: 769px) {
  .intro-banner__container__ctas__subscribed {
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    height: 56px;
  }
}
.intro-banner__container__ctas__subscribed--open {
  border-radius: 6px 6px 0px 0px;
}
.intro-banner__container__ctas__subscribed > button {
  all: unset;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: center;
}
.intro-banner__container__ctas__subscribed > button > span, .intro-banner__container__ctas__subscribed > button > svg {
  pointer-events: none;
}
.intro-banner__container__ctas__subscribed > button:hover {
  opacity: 0.9;
}
.intro-banner__container__ctas__subscribed__options {
  position: absolute;
  top: 42px;
  background: #272fa0;
  border: 2px solid #3845ff;
  box-sizing: content-box;
  border-radius: 0px 0px 6px 6px;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}
@media (min-width: 769px) {
  .intro-banner__container__ctas__subscribed__options {
    top: 56px;
    height: 56px;
  }
}
.intro-banner__container__ctas__subscribed__options--hide {
  display: none;
}
.intro-banner__container__ctas__subscribed__options > button {
  all: unset;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: center;
}
.intro-banner__container__ctas__subscribed__options > button:hover {
  opacity: 0.9;
}
.intro-banner__container__ctas__btn-txt {
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: none;
  white-space: nowrap;
  min-width: 180px;
  max-width: 380px;
  height: 60px;
  position: relative;
}
.intro-banner__container__ctas__buy-packs {
  margin-left: 12px;
  margin-top: 48px;
}
@media (max-width: 768px) {
  .intro-banner__container__ctas__buy-packs {
    display: none;
  }
}
.intro-banner__container__ctas__buy-packs::before {
  content: "";
  position: absolute;
  display: block;
  left: 20%;
  top: -95px;
  right: 20%;
  bottom: 0;
}
.intro-banner__container__ctas__buy-packs__pack {
  position: absolute;
  display: block;
  left: 0;
  top: -95px;
  right: 0;
  z-index: -1;
  text-align: center;
  max-height: 125px;
}
.intro-banner__container__ctas__buy-packs__pack img {
  max-width: 125px;
  width: 100%;
  max-height: 100%;
}